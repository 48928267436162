<template>
  <admin>
    <metatag title="Create Survey"></metatag>
    <page-header
      v-show="!isCreatingQuestion"
      :back="{ to: { name: 'survey' }, text: trans('Survey') }"
    >
    </page-header>

    <p v-show="isCreatingQuestion">
      <a @click="isCreatingQuestion = false">< Back</a>
    </p>

    <div v-if="!isCreatingQuestion">
      <v-form ref="form" v-model="valid" lazy-validation>
        <v-card class="mb-5">
          <v-card-title>Survey Information</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Title"
                  class="dt-text-field"
                  outlined
                  v-model="form.title"
                  :rules="rules.title"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  label="Slug"
                  class="dt-text-field"
                  outlined
                  v-model="slug"
                  :rules="rules.slug"
                ></v-text-field>
              </v-col>
              <v-col cols="12" v-if="!hidden">
                <v-select
                  :items="['custom', 'D-CSI', 'D-SSI']"
                  label="Type"
                  v-model="form.type"
                  outlined
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-select
                  :items="categories"
                  label="Categories"
                  item-text="name"
                  item-value="id"
                  v-model="form.category_id"
                  outlined
                  :rules="rules.category_id"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  name="input-7-1"
                  label="Description"
                  class="dt-text-area"
                  outlined
                  v-model="form.description"
                  :rules="rules.description"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-switch
                  v-model="form.status"
                  label="Status"
                  outlined
                ></v-switch>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        
        <v-card>
          <v-card-title>Questions Builder</v-card-title>
          <v-card-text class="pt-0">
            <v-row>
              <v-col cols="12">
                
                <v-alert class="pa-0">
                  <div>
                    Create questions by dragging and drop survey elements
                  </div>

                  <v-divider
                    class="my-4 info"
                    style="opacity: 0.22"
                  ></v-divider>

                  <v-row align="center" no-gutters>
                    <v-col class="grow">
                      Page will be redirected to the builder
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col class="shrink">
                      <v-btn  @click="toggleCreateQuestion" color="primary">
                        <span v-if="surveyInfo === '' || surveyInfo === null">Open Survey Builder</span>
                        <span v-else>Edit Survey Questions</span>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-alert>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <div class="d-flex justify-end mt-6">
          <v-btn
            large
            exact
            color="green darken-1"
            class="ma-1 white--text"
            :loading="loadingSave"
            @click="validate(false)"
          >
            <v-icon left>mdi-content-save</v-icon>
            Save
          </v-btn>
          <v-btn
            large
            exact
            color="green lighten-1"
            class="ma-1 white--text"
            :loading="loadingSaveAndExit"
            @click="validate(true)"
          >
            <v-icon left>mdi-content-save</v-icon>
            Save and Exit
          </v-btn>
          <v-btn
            large
            exact
            color="warning"
            class="ma-1 white--text"
            @click="cancel"
          >
            <v-icon left>mdi-close</v-icon>
            Cancel
          </v-btn>
        </div>
      </v-form>
    </div>

    <SurveyForm v-if="isCreatingQuestion" :title="form.title" />
  </admin>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SurveyForm from "../forms/SurveyForm.vue";

export default {
  name: "Create",
  components: {
    SurveyForm,
  },
  data() {
    return {
      hidden: true,
      form: {
        title: "",
        description: null,
        status: true,
        type: "custom",
        category_id: null
      },
      isCreatingQuestion: false,
      valid: false,
      rules: {
        title: [(value) => !!value || "Title is required"],
        slug: [(value) => !!value || "Slug is required"],
        // description: [(value) => !!value || "Description is required"],
        category_id: [(value) => !!value || "Category is required"],
      },
      loadingSaveAndExit: false,
      loadingSave: false,
      surveyInfo: ""
    };
  },
  methods: {
    ...mapActions({
      showSnackbar: "snackbar/show",
      createSurvey: "survey/save",
      getSurveyCategories: "survey/getSurveyCategories"
    }),
    toggleCreateQuestion() {
      if (this.isCreatingQuestion === false) {
        if (this.form.title == null || this.form.title == "") {
          this.showSnackbar({
            text: "Please enter title to create survey questions.",
            timeout: 2000,
          });
          return;
        }

        this.isCreatingQuestion = true;
        // if(isValid) this.isCreatingQuestion = true;
      } else {
        this.isCreatingQuestion = false;
      }
    },
    cancel() {
      this.$router.push({ name: "survey" });

      // DELETE SurveyJS Json
    },
    validate(isExit) {
      const self = this;

      const isValid = this.$refs.form.validate();

      let surveyForm = window.localStorage.getItem(this.form.title);

      let formData = {
        name: this.form.title,
        slug: this.slug,
        questions_data: (surveyForm != "") ? surveyForm : "",
        type: this.form.type,
        description: this.form.description,
        status: this.form.status,
        category_id: this.form.category_id
      };

      this.loadingSave = true;

      if (!isValid) return;
      if (isExit) {
        this.loadingSaveAndExit = true;

        self.createSurvey(formData)
            .then(function(data) {
                window.localStorage.removeItem(self.form.title)
                self.$router.push({ name: "survey" });
                self.loadingSave = false;
            });
      } else {
        self.createSurvey(formData)
            .then(function(data) {
                window.localStorage.removeItem(self.form.title)
                self.$router.go();
            });
      }
    },
    clearData()
    {
      const self = this;
      
      window.localStorage.removeItem(self.form.title)

      self.form.title = "";
      self.slug = "";
      self.form.type = "custom";
      self.form.description = "";
      self.form.status = 1;
    },
    getSurveyInfo() {
      const self = this;

      let surveyData = window.localStorage.getItem(self.form.title)

      self.surveyInfo = surveyData;
    }
  },
  computed: {
    ...mapGetters({
      categories: "survey/GET_SURVEY_CATEGORIES",
    }),
    slug: function () {
      return this.form.title.toLowerCase().replace(/ /g, "-");
    },
  },
  created: function() {
    window.addEventListener('mousemove', this.getSurveyInfo);
    this.getSurveyCategories({
      order_by: 'precedence',
      sort: 'asc'
    });
  },
  destroyed: function() {
    window.removeEventListener('mousemove', this.getSurveyInfo);
  }
};
</script>